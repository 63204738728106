<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Add Task
        </h1>
      </v-flex>
      <v-flex>
        <h4 v-if="project">PROJECT : {{ this.project.name }}</h4>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <!-- <v-flex xs12 md6 lg3 >
            <v-date-picker
              v-model="date"
              full-width
              class="mt-4"
            ></v-date-picker>
          </v-flex> -->
          <v-flex xs12 md6 lg8 class="mt-4">
            <v-layout row wrap>
              <v-flex xs12 md6>
                <h4 class="accent--text">Due Date</h4>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      readonly
                      single-line
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md1></v-flex>
              <v-flex xs12 md5>
                <h4 class="accent--text">Time</h4>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Select Time"
                      single-line
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <h4 class="fw-bold accent--text mt-3">Task</h4>
            <v-text-field single-line outlined v-model="title"></v-text-field>
            <h4 class="fw-bold accent--text mt-3">Description</h4>
            <v-textarea
              v-model="description"
              single-line
              outlined
              class="mt-2"
            ></v-textarea>
            <div class="text-right">
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="onFileChanged"
              />
              <v-progress-circular
                indeterminate
                color="primary"
                class="mt-5"
                v-if="uploading == true"
              ></v-progress-circular>
              <v-btn
                rounded
                class="pl-10 pr-10 mt-5"
                @click="handleFileImport"
                v-else
                ><v-icon class="mr-3">mdi-attachment</v-icon>Add
                Attachment</v-btn
              >
            </div>
            <div class="d-flex mt-5" style="flex-wrap: wrap">
              <div v-for="item in attachments" :key="item.name" class="mr-2">
                <p class="grey darken-4 pa-3" style="white-space: nowrap">
                  {{ getFileName(item.name) }}
                  <v-icon color="primary" @click="removeAttachment(item)"
                    >mdi-close</v-icon
                  >
                </p>
              </div>
            </div>

            <div v-if="selectedUser">
              <h4 class="mt-2 accent--text mb-2">Assign To</h4>

              <div class="d-flex">
                <img
                  :src="selectedUser.display_picture"
                  alt=""
                  style="width: 48px; height: 48px; border-radius: 24px"
                  v-if="selectedUser.display_picture"
                />
                <div
                  style="
                    width: 48px;
                    height: 48px;
                    background-color: #212121;
                    border-radius: 24px;
                  "
                  v-else
                ></div>
                <div class="pl-3">
                  <p class="pa-0 ma-0">{{ selectedUser.name }}</p>
                  <p class="pa-0 ma-0 accent--text">
                    {{ selectedUser.department.name }}
                  </p>
                </div>
              </div>
              <div v-if="!userInProject" class="accent--text mt-2 caption">
                <v-icon class="mr-2" color="accent">mdi-alert-outline</v-icon
                >This user is not a member of this project. User will be added
                to the project while saving this task.
              </div>
            </div>
            <v-checkbox
              v-model="close_after_deadline"
              label="Allow task to be closed after deadline"
            ></v-checkbox>
            <v-checkbox
              v-model="checkbox"
              label="Make this task visible to client"
            ></v-checkbox>
            <v-btn x-large class="primary pl-10 pr-10 mt-5" @click="saveTask"
              >Save Task</v-btn
            >
          </v-flex>
          <v-flex xs12 md4 class="pl-10 pr-10">
            <div class="d-flex justify-center">
              <clock style="width: 150px; height: 150px" />
            </div>
            <v-divider class="mt-5 mb-5"></v-divider>
            <h4 class="mb-2 accent--text">Assign To</h4>
            <v-text-field
              single-line
              outlined
              v-model="searchText"
              label="Type to search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>

            <div class="pl-5 pr-5">
              <div
                v-for="item in filteredUsers"
                :key="item._id"
                class="d-flex mt-4 align-center"
              >
                <div
                  v-if="isUserSelected(item._id)"
                  @click="selectedUser = null"
                >
                  <v-icon class="primary--text mr-3">mdi-check-circle</v-icon>
                </div>
                <div v-else @click="selectedUser = item">
                  <v-icon class="mr-3">mdi-plus-circle</v-icon>
                </div>
                <img
                  :src="item.display_picture"
                  alt=""
                  style="width: 48px; height: 48px; border-radius: 24px"
                  v-if="item.display_picture"
                />
                <div
                  style="
                    width: 48px;
                    height: 48px;
                    background-color: #212121;
                    border-radius: 24px;
                  "
                  v-else
                ></div>
                <div class="pl-3">
                  <p class="pa-0 ma-0">{{ item.name }}</p>
                  <p class="pa-0 ma-0 accent--text" v-if="item.department">
                    {{ item.department.name }}
                  </p>
                </div>
              </div>
            </div>
          </v-flex>
          <!-- <v-flex xs12 class="pa-3">
            <v-btn x-large class="primary black--text">Submit</v-btn>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
import Clock from "vue-html-analog-clock";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      users: [],
      time: null,
      title: "",
      description: "",
      project: null,
      parent_task: null,
      searchText: "",
      selectedUser: null,
      menu: false,
      attachments: [],
      files: [],
      fileName: "",
      imageSrc: null,
      uploading: false,
      close_after_deadline: true,
      enable_for_client: false,
    };
  },
  mounted() {
    if (this.$route.query.project) {
      this.fetchProject();
    }
    this.fetchUsers();
  },
  computed: {
    ...mapGetters(['USER']),
    filteredUsers() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.users.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        if (this.project) {
          
          return this.project.team;
        } else {
          
          return this.users;
        }
      }
    },
    userInProject() {
      if (this.project) {
        return this.project.team.some(
          (user) => user._id === this.selectedUser._id
        );
      } else {
        return true;
      }
    },
  },
  methods: {
    getFileName(fileName) {
      return (
        fileName.substring(0, 12) +
        "......." +
        fileName.substring(fileName.length - 10)
      );
    },
    handleFileImport() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.uploading = true;
      this.files = e.target.files;
      this.fileName = this.files[0].name;
      let url = BASE_URL + "/upload-file";
      let payload = new FormData();
      payload.append("photos", this.files[0]);
      let { data } = await Axios.post(url, payload);
      let attachment = {
        name: this.fileName,
        file: data,
      };
      this.attachments.push(attachment);
      this.uploading = false;
    },
    async fetchUsers() {
      let url = BASE_URL + "/user";
      let { data } = await Axios.get(url);
      this.users = data;
      
    },
    async fetchProject() {
      let url = BASE_URL + "/project/" + this.$route.query.project;
      let { data } = await Axios.get(url);
      this.project = data;
    },
    isUserSelected(id) {
      if (!this.selectedUser) {
        return false;
      }
      if (this.selectedUser._id == id) {
        return true;
      } else {
        return false;
      }
    },
    removeAttachment(_item) {
      let indexToRemove = this.attachments.findIndex((item) => item === _item);
      if (indexToRemove !== -1) {
        this.attachments.splice(indexToRemove, 1);
      }
    },
    async saveTask() {
      let url = BASE_URL + "";
      if (!this.selectedUser) {
        this.$toastr.e(
          "Please select a user to assign this task before saving",
          "User not selected"
        );
        return;
      }
      if (this.date && this.time && this.title) {
        let payload = {
          date: this.date,
          time: this.time,
          title: this.title,
          description: this.description,
          attachments: this.attachments,
          close_after_deadline: this.close_after_deadline,
          enable_for_client: this.enable_for_client,
          assign_to: this.selectedUser._id,
        };
        if (this.project) {
          payload.project = this.project._id;
        }
        if (this.parent_task) {
          payload.parent = this.parent_task._id;
        }
        if (this.userInProject) {
          payload.add_user_to_project = false;
        } else {
          payload.add_user_to_project = true;
        }
        let url = BASE_URL + "/task"
        const headers = {
        'Authorization' : this.USER.token
      }
        let {data} = await Axios.post(url,payload,{headers})
        this.$toastr.s(
          "Task has been saved and assigned to user",
          "Task saved"
        );
        this.$router.push('/tasks')
      }else{
        this.$toastr.e(
          "Please set a valid deadline and a title to save the task",
          "Incomplete information"
        );
      }
    },
  },
  components: { Clock },
};
</script>

<style>
.numbers {
  color: #212121 !important;
  font-size: 6pt !important;
}
.clock-outer-ring {
  background-color: #e09145 !important;
}

.clock-container {
  background-color: #fff !important;
}
.pointer {
  background-color: #212121 !important;
}
</style>